<template>
<b-card>
   
    <validation-observer ref="simpleRules">
        <b-form>
            <!-- Section Informations -->
            <h4> {{$t('Device Information')}} </h4>
            <span class="font-weight-bold">{{$t('The fields with (*) are required')}}</span>
            <hr class="mb-2">
            <b-row>

                <!-- Field: Device Model-->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Device Model')+'*'"  label-for="device-model">
                        <validation-provider #default="{ errors }" name="device-model" rules="required">
                            <v-select :state="errors.length > 0 ? false:null" v-model="deviceData.deviceModelId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="deviceOptions" :reduce="val => val.value" :clearable="false" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: Modem Identifier -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Modem Identifier')+'*'"  label-for="modem-identifier">
                        <validation-provider #default="{ errors }" name="modem-identifier" rules="required">
                            <b-form-input id="modem-identifier" v-model="deviceData.modemIdentifier" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>
            <h4 class="mt-2"> {{$t('SIM Card Information')}}</h4>
            <hr class="mb-2">
            <b-row>
                <!-- Field: Mobile Operator-->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t(' Mobile Operator')" label-for="mobile-operator">
                        <v-select v-model="deviceData.mobileOperatorId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="operatorOptions" :reduce="val => val.value" :clearable="false" input-id="mobile-operator" />
                    </b-form-group>
                </b-col>

                <!-- Field: Mobile Provider -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t(' Mobile Provider')" label-for="mobile-provider">
                        <v-select v-model="deviceData.mobileOperatorProviderId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="providerOptions" :reduce="val => val.value" :clearable="false" input-id="mobile-provider" />
                    </b-form-group>
                </b-col>

                <!-- Field: Sim Card Number -->
                <b-col cols="12" md="4">

                    <b-form-group :label="$t('SIM Card Number')" label-for="sim-card">
                        <vue-autosuggest v-model="searchQuery" :suggestions="filteredOptions" :input-props="inputProps" @selected="selectHandler" @input="onInputChange">
                            <template slot-scope="{suggestion}">
                                <span class="my-suggestion-item">{{ suggestion.item.number }}</span>
                            </template>
                        </vue-autosuggest>

                    </b-form-group>
                </b-col>

                <!-- Field: Notes -->
                <b-col cols="12" md="12">
                    <b-form-group :label="$t('Note')" label-for="note">
                        <b-form-textarea v-model="deviceData.note" id="note" rows="5" />
                    </b-form-group>
                </b-col>

                <!-- Btn: Save Submit -->
                <b-col v-if="!user.role.endsWith('VI')" cols="12">
                    <b-button variant="primary" type="submit" @click.prevent="validationForm">
                        {{$t('Save')}}
                    </b-button>
                </b-col>

            </b-row>
        </b-form>
    </validation-observer>
</b-card>
</template>

<script>
import {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
} from 'bootstrap-vue'
import {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BFormTextarea,
} from 'bootstrap-vue'
import {
    ref,
    onUnmounted
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import deviceStoreModule from '../deviceStoreModule'

import {
    VueAutosuggest
} from 'vue-autosuggest'

//import Cleave from 'vue-cleave-component'
import Cleave from 'cleave.js';
//import 'cleave.js/dist/addons/cleave-phone.br'

import vSelect from 'vue-select'

import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import {
    required,
    email,
    confirmed,
    url,
    between,
    alpha,
    integer,
    password,
    min,
    digits,
    alphaDash,
    length,
} from '@validations'

export default {

    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,

        ValidationProvider,
        ValidationObserver,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BCardText,
        BFormTextarea,

        VueAutosuggest,
        Cleave,
        vSelect

    },
    data() {
        return {
            myId: router.currentRoute.params.id,
            required,
            email,
            confirmed,
            url,
            between,
            alpha,
            integer,
            password,
            min,
            digits,
            alphaDash,
            length,

            datasuggest: [],
            filteredOptions: [],
            inputProps: {
                id: 'autosuggest__input',
                class: 'form-control',
                placeholder: "Search for Sim Cards ...",
            },
            limit: 3,
            selected: null,

            myMask: {
                //prefix: '55',
                delimiters: ['-'],
                blocks: [1, 7],
                numericOnly: true
                //uppercase: true,
                //phone: true,
                //phoneRegionCode: 'BR',
            },

        }
    },

    methods: {

        selectHandler(item) {
            console.log(item.item.name);
        },

        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    console.log(`sim card number -- `, this.deviceData.simCardNumber)
                    //if(this.deviceData.ope)
                    if (this.deviceData.id === "") {
                        console.log(`id deviceModelId`, this.deviceData.deviceModelId)
                        this.deviceData.simCardNumber = this.searchQuery
                        store.dispatch('app-device/addDevice', {

                                deviceData: this.deviceData
                            })
                            .then(response => {
                                //alert('Error 404')
                                this.msgDialog('Successful device registration', 'success')
                                router.push({
                                    name: 'list-device'
                                })

                            })
                            .catch(error => {
                                this.msgDialog('FAILURE to register device ', 'error')
                                // TODO: FAZER MSG ESPESSIFICA PARA CADAS ERRO DA API

                                console.log(error)
                            })
                    } else {
                        this.deviceData.simCardNumber = this.searchQuery

                        store.dispatch('app-device/updateDevice', {

                                deviceData: this.deviceData
                            })
                            .then(response => {
                                //alert('Error 404')
                                this.msgDialog('Successful device update', 'success')
                                //this.clearForm();

                            })
                            .catch(error => {
                                this.msgDialog('FAILURE to update device', 'error')

                            })
                    }
                }
            })
        },

        clearForm() {
            this.deviceData = {
                id: '',
                modemIdentifier: "",
                deviceModelId: "",
                mobileOperatorId: null,
                mobileOperatorProviderId: null,
                simCardNumber: "",
                customerId: "",
                note: "",

            }
        },

        msgDialog(msg, icon) {
            this.$swal({
                position: 'top-end',
                icon: icon,
                title: msg,
                showConfirmButton: false,
                timer: 1500 * 2,
                customClass: {
                    confirmButton: 'btn btn-primary',
                },

                buttonsStyling: false,
            })
        },

        selectHandler(option) {
            this.selected = option.item
            this.filteredOptions = []
            this.searchQuery = option.item.number
            this.deviceData.simCardNumber = option.item.number

            console.log(`option selecionado `, this.deviceData.customerId)
        },

        onInputChange(text) {
            if (text === '' || text === undefined || text.length < 3) {

                return
            }

            store.dispatch('app-device/fetchSimCard', {
                    q: this.searchQuery,

                })
                .then(response => {
                    console.log(`resposta auto `, response.data)

                    this.filteredOptions = [{
                        data: response.data.data,
                    }]

                })
                .catch(error => {

                    console.log(error)

                })
        },
        suggestionSelected() {
            console.log('Selecionado', this.selected)
        }
    },
    setup() {
        const deviceData = ref({
            id: '',
            modemIdentifier: "",
            deviceModelId: "",
            mobileOperatorId: null,
            mobileOperatorProviderId: null,
            simCardNumber: "",
            customerId: "",
            note: "",
        })
        const searchQuery = ref(``)

        const providerOptions = ref([])
        const operatorOptions = ref([])
        const deviceOptions = ref([])

        const DEVICE_APP_STORE_MODULE_NAME = 'app-device'

        // Register module
        if (!store.hasModule(DEVICE_APP_STORE_MODULE_NAME)) store.registerModule(DEVICE_APP_STORE_MODULE_NAME, deviceStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(DEVICE_APP_STORE_MODULE_NAME)) store.unregisterModule(DEVICE_APP_STORE_MODULE_NAME)
        })

      const user = localStorage.userData != undefined ? JSON.parse(localStorage.userData) : {}

        if (router.currentRoute.params.id != undefined) {

            store.dispatch('app-device/fetchDevice', {
                    id: router.currentRoute.params.id
                    //id: '099122db-1dad-4c62-819d-071e49f3298e'
                })
                .then(response => {
                    deviceData.value = response.data
                    console.log(`deviceData --`, response.data)

                    deviceData.value.id = response.data.id
                    deviceData.value.modemIdentifier = response.data.modemIdentifier
                    deviceData.value.simCardNumber = response.data.simCardNumber
                    deviceData.value.deviceModelId = response.data.deviceModelId

                    deviceData.value.note = response.data.note

                    if (response.data.simCardNumber) {
                        searchQuery.value = response.data.simCardNumber

                        store
                            .dispatch('app-device/fetchProvider', {
                                number: response.data.simCardNumber,

                            })
                            .then(res => {
                                deviceData.value.mobileOperatorId = res.data.mobileOperatorId;
                                deviceData.value.mobileOperatorProviderId = res.data.mobileOperatorProviderId;
                                console.log(`deviceData --`, deviceData.value)
                                
                            })
                            .catch((e) => {
                                console.log(e);

                            })

                    }

                })
                .catch(error => {
                    if (error.response.status === 404) {
                        deviceData.value = undefined
                    }
                })
        } else {
            deviceData.value = {

                id: '',
                modemIdentifier: "",
                deviceModelId: "",
                mobileOperatorId: null,
                mobileOperatorProviderId: null,
                simCardNumber: "",
                customerId: "",
                note: "",

            }
        }

        //Fetch Device Mobile Operators
        store.dispatch('app-device/fetchMobileOperators')
            .then(response => {
                response.data.data.map(function (v, key) {
                    operatorOptions.value.push({
                        label: v.name,
                        value: v.id
                    }, );
                });

            })
            .catch(error => {

            })

        //Fetch Device Mobile Providers
        store.dispatch('app-device/fetchMobileProviders')
            .then(response => {
                response.data.data.map(function (v, key) {
                    providerOptions.value.push({
                        label: v.name,
                        value: v.id
                    });
                });

            })
            .catch(error => {

            })

        //Fetch Device Models
        store.dispatch('app-device/fetchDeviceModel')
            .then(response => {
                response.data.data.map(function (v, key) {
                    deviceOptions.value.push({
                        label: v.name,
                        value: v.id
                    });
                });

            })
            .catch(error => {

            })

        return {
            deviceData,
            searchQuery,
            providerOptions,
            operatorOptions,
            deviceOptions,
          user
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>

<style>

</style>
